<template>
  <v-card>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
    >
      <v-tab
        v-for="(tabItem,index) in tabs"
        :key="tabItem.icon"
        @click="reRenderComponent(index)"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tabItem.icon }}
        </v-icon>
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <province :key="componentKey"></province>
      </v-tab-item>
      <v-tab-item>
        <regency :key="componentKey1"></regency>
      </v-tab-item>
      <v-tab-item>
        <district :key="componentKey2"></district>
      </v-tab-item>
      <v-tab-item>
        <village :key="componentKey3"></village>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import Province from './Province.vue'
import Regency from './Regency.vue'
import District from './District.vue'
import Village from './Village.vue'

export default {
  name: 'Tabs',
  components: {
    Province,
    Regency,
    District,
    Village,
  },
  props: {},
  data() {
    return {
      tab: '',
      tabs: [{ title: 'Provinsi' }, { title: 'Kabupaten/Kota' }, { title: 'Kecamatan' }, { title: 'Desa' }],
      componentKey: 0,
      componentKey1: 0,
      componentKey2: 0,
      componentKey3: 0,
    }
  },
  methods: {
    reRenderComponent(index) {
      switch (index) {
        case 0:
          this.componentKey += 1
          break
        case 1:
          this.componentKey1 += 1
          break
        case 2:
          this.componentKey2 += 1
          break
        case 3:
          this.componentKey3 += 1
          break
        default:
          break
      }
    },
  },
}
</script>

<style>
</style>
